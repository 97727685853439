import React from "react";
import "../../home/styles.css";

const Notes = ({ borderRadius, width, theme }) => {
  return (
    <div
      className={`chatBox`}
      style={{
        borderRadius: borderRadius ? borderRadius : 0,
        width: width,
        height: "20%",
        boxShadow: theme === "dark" ? "unset" : "1px 1px 10px #c4c4c4",
        backgroundColor: theme === "dark" ? "#151515" : "#fff",
      }}
    >
      <p style={{ color: "grey", fontSize: 16, margin: 20 }}>Take notes...</p>
      <div
        className="align-items-center justify-content-center"
        style={{
          backgroundColor: "#3478ff",
          alignSelf: "flex-end",
          margin: 10,
          borderRadius: 6,
        }}
      >
        <p
          style={{
            margin: 0,
            color: "#ffffff",
            paddingInline: 10,
            paddingBlock: 6,
          }}
        >
          Watch Now
        </p>
      </div>
    </div>
  );
};

export default Notes;
