import React from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";

const Sermon = ({ borderRadius, width, theme }) => {
  const sermons = [
    { id: 1, name: "Blessed", img: Assets.sermon1 },
    { id: 2, name: "Stride, Not strive", img: Assets.sermon2 },
  ];

  const series = [
    { id: 3, name: "Nicholes series", img: Assets.sermon3 },
    { id: 4, name: "Believe", img: Assets.sermon4 },
    { id: 5, name: "Andy's series", img: Assets.sermon5 },
    { id: 6, name: "Purity", img: Assets.sermon6 },
  ];

  return (
    <div
      className={`d-flex`}
      style={{
        backgroundColor: theme == "dark" ? "#151515" : "#fff",
        width: width,
        height: "30%",
        borderRadius: borderRadius ? borderRadius : 0,
        padding: 10,
        columnGap: 4,
        overflow: "hidden",
        boxShadow: theme === "dark" ? "unset" : "1px 1px 10px #c4c4c4",
      }}
    >
      {/*1st  */}
      <div className="col-md-6">
        <p
          style={{
            color: theme === "dark" ? "#ffffff" : "#000",
            fontSize: 16,
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Sermons
        </p>
        <div
          className="d-flex flex-column"
          style={{
            rowGap: 10,
            marginTop: 6,
          }}
        >
          {sermons.map((item) => {
            return (
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: theme === "dark" ? "#232323" : "#d8d8d8",
                  borderRadius: 6,
                  padding: 4,
                  columnGap: 10,
                }}
              >
                <img
                  src={item.img}
                  style={{ height: 50, width: 60, borderRadius: 6 }}
                />
                <p
                  style={{
                    color: theme === "dark" ? "#ffffff" : "#000",
                    fontSize: 12,
                    margin: 0,
                    flexWrap: "wrap",
                  }}
                >
                  {item.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      {/* 2nd */}
      <div className="col-md-6">
        <p
          style={{
            color: theme === "dark" ? "#ffffff" : "#000",
            fontSize: 16,
            fontWeight: "bold",
            margin: 0,
          }}
        >
          Sermons Series
        </p>
        <div
          className="d-flex flex-column"
          style={{
            rowGap: 10,
            marginTop: 6,
          }}
        >
          {series.map((item) => {
            return (
              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor:
                    item.id == 3
                      ? "#007bff"
                      : theme === "dark"
                      ? "#232323"
                      : "#d8d8d8",
                  borderRadius: 6,
                  padding: 4,
                  columnGap: 10,
                }}
              >
                <img
                  src={item.img}
                  style={{ height: 50, width: 60, borderRadius: 6 }}
                />
                <p
                  style={{
                    color:
                    item.id == 3 && theme === "light"
                      ? "#ffffff"
                      : theme === "light"
                      ? "#000"
                      : "#fff",
                    fontSize: 12,
                    margin: 0,
                    flexWrap: "wrap",
                  }}
                >
                  {item.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sermon;
