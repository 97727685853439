import React, { useState, useRef, useEffect } from "react";
import Assets from "../../assets/images";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getRequest, postRequest } from "../../utills/requests";
import {
  emptyWidgetStyles,
  getStyling,
  setActiveTemplate,
  setEdit,
  setSideBar,
  setStyling,
  startLoading,
  stopLoading,
  submitRequest,
  updateTracker,
  updateUniversalStyles,
} from "../../store/slices/bodyStyling";
import { Circles } from "react-loader-spinner";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setPageId,
  setPages,
  setPageType,
  setTemplatesArray,
} from "../../store/slices/loginSlice";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { CirclePlus, Cross, RefreshCcw, Trash2, Upload, X } from "lucide-react";
import {
  Dropdown,
  DropdownButton,
  Modal,
  Button,
  Form,
  ButtonGroup,
} from "react-bootstrap";

function Topbar() {
  const dispatch = useDispatch();
  // const pages = useSelector((state) => state.bodyStyling.styling.pages);
  const pages = useSelector((state) => state.login.pages);
  const templates = useSelector((state) => state.login.templatesArray);
  const activeTemplate = useSelector(
    (state) => state.bodyStyling.activeTemplate
  );

  const changeTracker = useSelector((state) => state.bodyStyling.changeTracker);
  const order = useSelector((state) => state.bodyStyling.homePageOrder);
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const loader = useSelector((state) => state.bodyStyling.submitLoader);
  const widgetsStyles = useSelector((state) => state.bodyStyling.widgetsStyles);
  const sideBarState = useSelector((state) => state.bodyStyling.sideBarState);
  const pageId = useSelector((state) => state.login.pageId);
  const pageType = useSelector((state) => state.login.pageType);

  const [localPageId, setLocalPageId] = useState("");

  const [templateLoader, setTemplateLoader] = useState(false);

  const [show, setShow] = useState(false);
  const [delTemplateVisible, setDelTemplateVisible] = useState(null);

  const handleTempDelClose = () => setDelTemplateVisible(null);
  const handleTempDelOpen = (id) => setDelTemplateVisible(id);

  const [err, setErr] = useState(false);
  const handleClose = () => {
    setShow(false);
    setErr("");
  };
  const handleShow = () => setShow(true);
  const [templateName, setTemplateName] = useState("");

  const location = useLocation();
  const { pathname } = location;

  const mappingMobileRoutes = {
    "/home": "/mobile-home",
    "/watch": "/mobile-watch",
    "/giving": "/mobile-giving",
    "/team": "/mobile-team",
    "/group": "/mobile-group",
    "/serve": "/mobile-serve",
    "/kids": "/mobile-kids",
    "/userGiving": "/mobileUserGiving",
  };

  const mappingWebRoutes = {
    "/mobile-home": "/home",
    "/mobile-watch": "/watch",
    "/mobile-giving": "/giving",
    "/mobile-team": "/team",
    "/mobile-group": "/group",
    "/mobile-serve": "/serve",
    "/mobile-kids": "/kids",
    "/mobileUserGiving": "/userGiving",
  };

  const mappingRouteIds = {
    1: "home",
    2: "watch",
    3: "giving",
    4: "group",
    5: "team",
    6: "serve",
    7: "kids",
    8: "userGiving",
    9: "mobile-home",
    10: "mobile-watch",
    11: "mobile-giving",
    12: "mobile-group",
    13: "mobile-team",
    14: "mobile-serve",
    15: "mobile-kids",
    16: "mobileUserGiving",
  };

  useEffect(() => {
    if (pathname.includes("mobile")) {
      dispatch(setPageType("mobile"));
    } else {
      dispatch(setPageType("web"));
    }
  }, [pathname]);

  const ref = useRef(null);

  const [localPages, setLocalPages] = useState([]);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const screenWidth = document.body.clientWidth;
    const data = JSON.parse(JSON.stringify(order));
    data["universalStyles"] = { ...universalStyles, screen_width: screenWidth };
    data["widgetStyles"] = widgetsStyles;
    dispatch(submitRequest(true));
    try {
      const resp = await postRequest({
        endpoint: "/edit-current-styles",
        payload: data,
      });
      toast.success(resp.data.msg, { id: "changesMade" });
      dispatch(updateTracker(false));
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(setEdit({}));
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
      dispatch(setSideBar(false));
    } catch (err) {
      toast.error(
        err.response === undefined
          ? "Something went wrong"
          : err.response.data.message,
        { id: "updateErr" }
      );
    } finally {
      dispatch(submitRequest(false));
    }
  };

  useEffect(() => {
    if (pages.length > 0) {
      if (pageType === "web") {
        setLocalPages(pages.slice(0, 8));
      } else {
        setLocalPages(pages.slice(8));
      }
    }
  }, [pages]);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(setPageType("web"));
    navigate("/");
  };
  const goBack = () => {
    if (sideBarState && changeTracker) {
      dispatch(getStyling());
      dispatch(updateTracker(false));
    }
    const sidebaroption = document.querySelector(".sidebaroption");
    const sidebaroption2 = document.querySelector(".sidebaroption2");
    const widgetMain = document.querySelector(".custom-widgetMain");
    sidebaroption.classList.remove("hide");
    sidebaroption2.classList.remove("hide");
    widgetMain.classList.remove("show");
    localStorage.removeItem("customId");
    dispatch(setSideBar(false));
    dispatch(setEdit({}));
    localStorage.removeItem("widgetName");
  };

  const handleToggle = (type, path) => {
    if (type === "web") {
      navigate(mappingWebRoutes[path]);
    } else {
      navigate(mappingMobileRoutes[path]);
    }
    dispatch(setPageType(type));
    dispatch(setActiveTemplate({ id: null, name: "" }));
  };

  const navigatePage = (pageId) => {
    dispatch(setPageId(pageId));
    navigate(`/${mappingRouteIds[pageId]}`);
  };

  const saveTemplate = async (type) => {
    if (type === "new" && templateName.trim().length == 0) {
      setErr("Please enter template name.");
      return;
    } else {
      setErr("");
    }

    const screenWidth = document.body.clientWidth;
    const data = JSON.parse(JSON.stringify(order));
    data["universalStyles"] = { ...universalStyles, screen_width: screenWidth };
    data["widgetStyles"] = widgetsStyles;
    data["templateName"] = templateName;

    if (type === "update" && activeTemplate && activeTemplate.id) {
      data["templateId"] = activeTemplate.id;
    } else {
      data["templateId"] = null;
    }

    if (type === "new") {
      setTemplateLoader(true);
    } else {
      dispatch(submitRequest(true));
    }

    try {
      const resp = await postRequest({
        endpoint: "/edit-template-styles",
        payload: data,
      });
      if (resp.data.success) {
        toast.success(resp.data.msg);
        handleClose();
        if (type === "new") {
          const templatesData = [
            ...templates,
            { id: resp.data.template_id, name: templateName },
          ];
          dispatch(setTemplatesArray(templatesData));
          setTemplateName("");
          dispatch(
            setActiveTemplate({ id: resp.data.template_id, name: templateName })
          );
        }
      }
      console.log("REEE>", resp.data);
    } catch (error) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      }
    } finally {
      setTemplateLoader(false);
      dispatch(submitRequest(false));
    }
  };

  const getTemplateStyles = async (template) => {
    dispatch(startLoading(true));
    dispatch(submitRequest(true));
    try {
      const res = await getRequest({
        endpoint: `/get-template-styles?page_id=${pageId}&template_id=${template.id}`,
      });
      dispatch(setActiveTemplate(template));

      dispatch(emptyWidgetStyles());
      dispatch(setStyling(res));
      dispatch(stopLoading(false));
      // dispatch(submitRequest(false));
      dispatch(updateUniversalStyles(res.universal_styles));
      dispatch(setPages(res.pages));
      dispatch(setPageId(pageId));
      dispatch(setTemplatesArray(res.templates));
      dispatch(updateTracker(true));
    } catch (err) {
      dispatch(stopLoading());
      toast.error(
        err.response === undefined
          ? "Something went wrong"
          : err.response.data.message,
        { id: "styleErr" }
      );
    } finally {
      dispatch(submitRequest(false));
    }
  };

  const deleteTemplate = async (tempId) => {
    try {
      setTemplateLoader(true);
      const res = await postRequest({
        endpoint: `/delete-template`,
        payload: { template_id: tempId, page_id: pageId },
      });
      if (res.data.msg) {
        handleTempDelClose(null);
        toast.success(res.data.msg);
        const updatedTemps = templates.filter((item) => item.id !== tempId);
        dispatch(setTemplatesArray(updatedTemps));

        if (activeTemplate && activeTemplate.id == tempId) {
          dispatch(setActiveTemplate({ id: null, name: "" }));
        }
      }
      console.log("DELETE>", res.data);
    } catch (err) {
      console.log("ERR>", err);
    } finally {
      setTemplateLoader(false);
    }
  };

  return (
    <>
      <div className="maintopbar">
        <div className="maintopbarInnerLeft">
          <div className="logomain">
            <img src={Assets.logo} alt="Logo Main" />
          </div>
        </div>
        <div className="maintopbarInnerRight">
          <div className="topbarrightcol topbarrightcol-1">
            <div className="topbarrightcolinner">
              <div
                className="backbtn"
                onClick={() => goBack()}
                style={{
                  cursor: sideBarState ? "pointer" : "unset",
                  opacity: sideBarState ? 1 : 0.2,
                }}
              >
                <img src={Assets.backarrow} alt="Back Arrow" />
                <span>Back</span>
              </div>
              <div className="pageDropdown">
                <span>page:</span>
                <select
                  value={pageId}
                  className="watchSelect"
                  onChange={(e) => {
                    dispatch(setActiveTemplate({ id: null, name: "" }));
                    if (changeTracker) {
                      setLocalPageId(e.target.value);
                      ref.current.open();
                      return;
                    } else {
                      navigatePage(e.target.value);
                    }

                    // close widget size side bar
                    const sidebaroption =
                      document.querySelector(".sidebaroption");
                    const sidebaroption2 =
                      document.querySelector(".sidebaroption2");
                    const widgetMain =
                      document.querySelector(".custom-widgetMain");
                    sidebaroption.classList.remove("hide");
                    sidebaroption2.classList.remove("hide");
                    widgetMain.classList.remove("show");
                    localStorage.removeItem("customId");
                    dispatch(setSideBar(false));
                    dispatch(setEdit({}));
                    localStorage.removeItem("widgetName");
                  }}
                >
                  {localPages.map((page) => {
                    return (
                      <option key={page.id} id={page.id} value={page.id}>
                        {page.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="backbtn undoBtn" style={{ marginLeft: 15 }}>
                <img src={Assets.undoico} alt="Undo Icon" />
                {/* <span>Undo</span> */}
              </div>
            </div>
          </div>
          <div className="topbarrightcol topbarrightcol-2">
            <div className="topbarrightcolinner">
              <ul>
                <li
                  onClick={() => handleToggle("web", pathname)}
                  className={` ${pageType == "web" ? "active" : ""}`}
                >
                  <img src={Assets.laptop} alt="Laptop" />
                </li>
                <li
                  onClick={() => handleToggle("mobile", pathname)}
                  className={` ${pageType == "mobile" ? "active" : ""}`}
                >
                  <img src={Assets.smartphone} alt="Smart Phone" />
                </li>
              </ul>
            </div>
          </div>
          <div className="topbarrightcol topbarrightcol-3">
            <div className="topbarrightcolinner">
              <Dropdown as={ButtonGroup} drop="start">
                <Button
                  variant="primary"
                  style={{
                    width: 110,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    textAlign: "left",
                    pointerEvents: "none",
                  }}
                >
                  {activeTemplate && activeTemplate.name
                    ? activeTemplate.name
                    : "Templates"}
                </Button>

                <Dropdown.Toggle
                  split
                  variant="primary"
                  id="dropdown-split-basic"
                />

                <Dropdown.Menu>
                  <Dropdown.Header
                    style={{
                      color: "#000",
                      fontWeight: "bold",
                      fontSize: 16,
                      paddingBlock: 0,
                      pointerEvents: "none",
                    }}
                  >
                    Select Template
                  </Dropdown.Header>
                  <Dropdown.Divider />
                  {templates && templates.length > 0 ? (
                    templates.map((temp) => {
                      return (
                        <Dropdown.Item
                          className="d-flex align-items-center justify-content-between"
                          onClick={() => getTemplateStyles(temp)}
                          key={temp.id}
                          active={
                            activeTemplate && activeTemplate.id == temp.id
                          }
                          // style={{
                          //   maxWidth: 190,
                          //   textOverflow: "ellipsis",
                          //   overflow: "hidden",
                          // }}
                        >
                          <h6
                            style={{
                              maxWidth: 140,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              margin: 0,
                            }}
                          >
                            {temp.name}
                          </h6>
                          <Trash2
                            onClick={(e) => {
                              handleTempDelOpen(temp.id);
                              e.stopPropagation();
                            }}
                            color="red"
                            size={20}
                          />
                        </Dropdown.Item>
                      );
                    })
                  ) : (
                    <Dropdown.Item disabled>No Templates</Dropdown.Item>
                  )}
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleShow}>
                    <CirclePlus
                      size={20}
                      color="#000"
                      style={{ marginRight: 10 }}
                    />
                    Save as Template
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={
                      activeTemplate && activeTemplate.id ? false : true
                    }
                    onClick={() => saveTemplate("update")}
                  >
                    <RefreshCcw
                      size={20}
                      color="#000"
                      style={{
                        marginRight: 10,
                        opacity: activeTemplate && activeTemplate.id ? 1 : 0.3,
                      }}
                    />
                    Update Template
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <div className="d-flex defaultButtons" style={{ columnGap: 15 }}>
                <button
                  className="BlueButton"
                  onClick={handleSubmit}
                  disabled={!changeTracker}
                  style={{
                    opacity: !changeTracker ? 0.6 : 1,
                    minWidth: 110,
                  }}
                >
                  {loader ? (
                    <Circles
                      height="20"
                      width="50"
                      color="#4b6cb7"
                      ariaLabel="circles-loading"
                      wrapperStyle={{ justifyContent: "center" }}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    <>
                      <Upload color="#fff" size={18} /> Publish
                    </>
                  )}
                </button>
                <button onClick={handleLogout} className="ligthButton">
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* DISCARD CHANGES */}
      <Popup
        lockScroll={true}
        contentStyle={{
          border: "unset",
          padding: 0,
          borderRadius: 10,
        }}
        ref={ref}
        modal={true}
        trigger={<div style={{ marginLeft: "unset" }} />}
        position="center center"
      >
        {(close) => (
          <div
            className="confirmation-dialog"
            style={{ backgroundColor: "black", borderRadius: 10, padding: 20 }}
          >
            <X
              onClick={close}
              color="grey"
              style={{
                position: "absolute",
                top: 4,
                right: 4,
                cursor: "pointer",
              }}
            />
            <p
              style={{
                fontSize: 20,
                color: "#ffffff",
                textAlign: "center",
                marginTop: 6,
                paddingInline: 10,
              }}
            >
              Current changes will be discarded?
              <br /> Would you like to save?
            </p>
            <div className="d-flex align-items-center justify-content-around">
              <button
                onClick={() => {
                  ref.current.close();
                  handleSubmit();
                }}
                style={{
                  fontSize: 20,
                  color: "grey",
                  outline: "unset",
                  backgroundColor: "transparent",
                  fontWeight: "bolder",
                }}
              >
                Yes
              </button>
              <button
                onClick={() => {
                  ref.current.close();
                  navigatePage(localPageId);
                  dispatch(updateTracker(false));
                }}
                style={{
                  fontSize: 20,
                  color: "red",
                  outline: "unset",
                  backgroundColor: "transparent",
                  fontWeight: "bolder",
                }}
              >
                No, Discard
              </button>
            </div>
          </div>
        )}
      </Popup>
      {/* TEMPLATE NAME */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title style={{ color: "#fff" }}>Save New Template</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            saveTemplate("new");
          }}
        >
          <Modal.Body>
            <Form.Group className="mb-3" controlId="templateName">
              <Form.Label style={{ color: "#fff" }}>Template Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter template name"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                autoFocus
                style={{ backgroundColor: "#fff", color: "#000" }}
              />
              {err && (
                <p style={{ color: "red", fontSize: 14, fontWeight: "bold" }}>
                  {err}
                </p>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {templateLoader ? (
                <Circles
                  height="20"
                  width="50"
                  color="#4b6cb7"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* DLETE TEMPLATE */}
      <Modal
        show={delTemplateVisible ? true : false}
        onHide={handleTempDelClose}
      >
        <Modal.Header>
          <Modal.Title style={{ color: "#fff" }}>Delete Template</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            deleteTemplate(delTemplateVisible);
          }}
        >
          <Modal.Body>
            <Form.Group className="mb-3" controlId="templateName">
              <Form.Label style={{ color: "#fff" }}>
                Are you sure you want to delete this template?
              </Form.Label>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleTempDelClose}>
              Cancel
            </Button>
            <Button variant="danger" type="submit">
              {templateLoader ? (
                <Circles
                  height="20"
                  width="50"
                  color="#fff"
                  ariaLabel="circles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                "Delete"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default Topbar;
