import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  loginError: null,
  userData: null,
  pageId: "",
  pages: [],
  pageType: "web",
  templatesArray: [],
};

const slice = createSlice({
  name: "login",
  initialState,
  reducers: {
    requestLogin(state, action) {
      state.isLoading = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.loginError = action.payload;
    },
    loginSuccess(state, action) {
      state.isLoading = false;
      state.userData = action.payload;
    },
    setPageId(state, action) {
      state.pageId = action.payload;
    },
    setPages(state, action) {
      state.pages = action.payload;
    },
    setPageType(state, action) {
      state.pageType = action.payload;
    },
    setTemplatesArray(state, action) {
      state.templatesArray = action.payload;
    },
   
  },
});

// Reducer
export default slice.reducer;
//Actions
export const {
  requestLogin,
  hasError,
  loginSuccess,
  setPageId,
  setPages,
  setPageType,
  setTemplatesArray
} = slice.actions;
