import React, { useEffect, useState, useRef } from "react";
import Assets from "../../assets/images";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setEdit,
  setSideBar,
  setStopDrag,
  setStyling,
  submitRequest,
  updateTracker,
  updateUniversalStyles,
} from "../../store/slices/bodyStyling";
import { postRequest } from "../../utills/requests";
import toast from "react-hot-toast";
import DraggableItem from "./DraggableItem";
import DragableTool from "./DragableTool";
import { SketchPicker } from "react-color";
import useOutsideClick from "../../utills/outerClick";

function Sidebar(props) {
  const { handleLightMode, handleDarkMode, themeMode, borderCorners } = props;
  const dispatch = useDispatch();
  const editingMode = useSelector((state) => state.bodyStyling.isEdit);
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const widgets = useSelector((state) => state.bodyStyling.styling);
  const updatedOrder = useSelector((state) => state.bodyStyling.homePageOrder);
  const widgetsStyles = useSelector((state) => state.bodyStyling.widgetsStyles);
  const loading = useSelector((state) => state.bodyStyling.isLoading);
  const changeTracker = useSelector((state) => state.bodyStyling.changeTracker);
  const pageId = useSelector((state) => state.login.pageId);
  const pageType = useSelector((state) => state.login.pageType);

  const [customBorder, setCustomBorder] = useState(
    universalStyles.corners ? universalStyles.corners : "round"
  );
  //Main color
  const [mainColor, setMainColor] = useState("#3478ff");

  useEffect(() => {
    setCustomBorder(universalStyles.corners);
    setError(false);
    setMainColor(universalStyles.mainColor);

    if (universalStyles.default_widget_colors) {
      try {
        const colors = JSON.parse(universalStyles.default_widget_colors);
        setWidgetColour(colors);
        setDefaultFontColor({
          text: colors.text,
          heading: colors.heading,
          paragraph: colors.paragraph,
        });
      } catch (error) {
        const colors = universalStyles.default_widget_colors;
        setWidgetColour(colors);
        setDefaultFontColor({
          text: colors.text,
          heading: colors.heading,
          paragraph: colors.paragraph,
        });
      }
    }
  }, [universalStyles && universalStyles.corners]);

  const getHighestYValue = () => {
    if (widgets.homepageOrder && widgets.homepageOrder.length === 0) {
      return 0; // or any default value if the array is empty
    }
    const highestYItem =
      widgets.homepageOrder &&
      widgets.homepageOrder.reduce((maxYItem, item) => {
        return item.y > maxYItem.y ? item : maxYItem;
      }, widgets.homepageOrder[0]);
    const highestYWithH = highestYItem.y + highestYItem.h;
    return highestYWithH;
  };

  //deep copy
  const updateStyles = JSON.parse(JSON.stringify(universalStyles));

  useEffect(() => {
    if (!themeMode) {
      LighthandleClick();
    }
    if (borderCorners === "sharp") {
      sharphandleClick();
    } else {
      roundhandleClick();
    }
  }, [themeMode, borderCorners]);

  useEffect(() => {
    if (universalStyles.theme == "dark") {
      DarkhandleClick();
    } else {
      LighthandleClick();
    }
  }, [universalStyles && universalStyles.theme]);

  // light and dark
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isLightMode, setIsLightMode] = useState(true);
  // round and sharp
  const [isroundkMode, setIsroundMode] = useState(false);
  const [issharpMode, setIssharpMode] = useState(false);
  // font widget
  const [isgilorykMode, setIsgiloryMode] = useState(false);
  const [isplayMode, setIsplayMode] = useState(false);
  const [isfrontMode, setIsfrontMode] = useState(false);
  const [isbackMode, setIsbackMode] = useState(false);
  //Name Input
  const [widgetName, setWidgetName] = useState("");
  const [error, setError] = useState(false);
  const [advanceDrop, setAdvanceDrop] = useState(false);

  const GiloryhandleClick = () => {
    setIsgiloryMode(!isgilorykMode);
    const updatedStyles = { ...updateStyles, font: "gilroymedium" };
    dispatch(updateUniversalStyles(updatedStyles));
  };
  const playhandleClick = () => {
    setIsplayMode(!isplayMode);
    const updatedStyles = { ...updateStyles, font: "playfair display" };
    dispatch(updateUniversalStyles(updatedStyles));
  };
  const fronthandleClick = () => {
    setIsfrontMode(!isfrontMode);
    const updatedStyles = { ...updateStyles, font: "frontageregular" };
    dispatch(updateUniversalStyles(updatedStyles));
  };
  const rockhandleClick = () => {
    setIsbackMode(!isbackMode);
    const updatedStyles = { ...updateStyles, font: "rockwellregular" };
    dispatch(updateUniversalStyles(updatedStyles));
  };
  const DarkhandleClick = () => {
    setIsDarkMode(!isDarkMode);
    const body = document.querySelector("body");
    const lightbox = document.querySelector(".lightbox");
    const darkbox = document.querySelector(".darkbox");
    darkbox.classList.add("active");
    lightbox.classList.remove("active");
    body.classList.remove("light");
    body.classList.add("dark");
    handleDarkMode();
    const updatedStyles = { ...updateStyles, theme: "dark" };
    dispatch(updateUniversalStyles(updatedStyles));
  };

  const LighthandleClick = () => {
    setIsLightMode(!isLightMode);
    const body = document.querySelector("body");
    const lightbox = document.querySelector(".lightbox");
    const darkbox = document.querySelector(".darkbox");
    darkbox.classList.remove("active");
    lightbox.classList.add("active");
    body.classList.remove("dark");
    body.classList.add("light");
    // handleLightMode();
    const updatedStyles = { ...updateStyles, theme: "light" };
    dispatch(updateUniversalStyles(updatedStyles));
  };

  const roundhandleClick = () => {
    setIsroundMode(!isroundkMode);
    const body = document.querySelector("body");
    const round = document.querySelector(".roundcorner");
    const sharp = document.querySelector(".sharpcorner");
    round.classList.add("active");
    sharp.classList.remove("active");
    body.classList.remove("sharpMode");
    body.classList.add("roundMode");
    const updatedStyles = { ...updateStyles, corners: "round" };
    dispatch(updateUniversalStyles(updatedStyles));
  };
  const sharphandleClick = () => {
    setIssharpMode(!issharpMode);
    const body = document.querySelector("body");
    const round = document.querySelector(".roundcorner");
    const sharp = document.querySelector(".sharpcorner");
    round.classList.remove("active");
    sharp.classList.add("active");
    body.classList.add("sharpMode");
    body.classList.remove("roundMode");
    const updatedStyles = { ...updateStyles, corners: "sharp" };
    dispatch(updateUniversalStyles(updatedStyles));
  };

  const getWidgetId = async (w, h, name) => {
    // if (widgetName.trim().length === 0) {
    //   setError(true);
    //   return;
    // }
    const data = {
      // name: widgetName ? widgetName :  "default widget",
      name: name,
      layout: { w, h },
      defaultBgColor: "#d2d2a7",
      // pageId: "1",
      pageId: pageId,
    };
    dispatch(submitRequest(true));
    try {
      const res = await postRequest({
        endpoint: "/add-new-widget",
        payload: data,
      });
      if (res.data.success) {
        localStorage.setItem("customId", res.data.widget_id);
        localStorage.setItem("widgetName", name);
        dispatch(
          setEdit({
            isEditMode: false,
            widgtId: res.data.widget_id,
          })
        );
        dispatch(setStopDrag("yes"));
        toast.success(res.data.msg);
        handleResizing(w, h, name);
        setWidgetName("");
        setError(false);
        dispatch(updateTracker(true));
      }
    } catch (err) {
      console.log("ERR>>", err);
    } finally {
      dispatch(submitRequest(false));
    }
  };

  const openCustomSideBar = (widgetName) => {
    //initial size
    // OLD
    // widgetName === "column" || widgetName === "row"
    //   ? getWidgetId( 4, 6, widgetName)
    //   : pageId == 1
    //   ? getWidgetId(1, 3, widgetName)
    //   : getWidgetId(2, 3, widgetName);

    widgetName === "column" || widgetName === "row"
      ? getWidgetId(pageId == 1 ? 2 : pageId == 6 ? 6 : 4, 6, widgetName)
      : pageId == 1
      ? getWidgetId(1, 3, widgetName)
      : pageId == 5 || pageId == 6
      ? getWidgetId(3, 3, widgetName)
      : getWidgetId(2, 3, widgetName);

    const sidebaroption = document.querySelector(".sidebaroption");
    const sidebaroption2 = document.querySelector(".sidebaroption2");
    const widgetMain = document.querySelector(".custom-widgetMain");
    sidebaroption.classList.add("hide");
    sidebaroption2.classList.add("hide");
    widgetMain.classList.add("show");
  };

  const closeWidgetSideBar = () => {
    setError(false);
    // setCustomBorder(universalStyles.corners);
    localStorage.removeItem("customId");
    localStorage.removeItem("widgetName");
    dispatch(setEdit({}));
    const sidebaroption = document.querySelector(".sidebaroption");
    const sidebaroption2 = document.querySelector(".sidebaroption2");
    const widgetMain = document.querySelector(".custom-widgetMain");
    sidebaroption.classList.remove("hide");
    sidebaroption2.classList.remove("hide");
    widgetMain.classList.remove("show");
    dispatch(setSideBar(false));

    changeTracker && saveChangesApi();
  };

  const saveChangesApi = async () => {
    const data = JSON.parse(JSON.stringify(updatedOrder));
    data["universalStyles"] = universalStyles;
    data["widgetStyles"] = widgetsStyles;
    dispatch(submitRequest(true));
    try {
      const resp = await postRequest({
        endpoint: "/edit-current-styles",
        payload: data,
      });
      toast.success(resp.data.msg, { id: "changesMade" });
      dispatch(updateTracker(false));
    } catch (err) {
      toast.error(
        err.response === undefined
          ? "Something went wrong"
          : err.response.data.message,
        { id: "updateErr" }
      );
    } finally {
      dispatch(submitRequest(false));
    }
  };

  // const handleEdit = (id) => {
  //   // dispatch(updateTracker(true));
  //   if (editingMode.widgtId !== id.toString()) {
  //     dispatch(
  //       setEdit({
  //         isEditMode: false,
  //         widgtId: id.toString(),
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       setEdit({
  //         isEditMode: true,
  //         widgtId: "",
  //       })
  //     );
  //   }
  //   localStorage.setItem("customId", id);
  //   const sidebaroption = document.querySelector(".sidebaroption");
  //   const sidebaroption2 = document.querySelector(".sidebaroption2");
  //   const widgetMain = document.querySelector(".custom-widgetMain");
  //   sidebaroption.classList.add("hide");
  //   sidebaroption2.classList.add("hide");
  //   widgetMain.classList.add("show");
  //   dispatch(setSideBar(true));
  // };

  const { homepageOrder } = widgets;

  function updateObjects(arr1, arr2) {
    // Create a map for quick lookup of objects from arr2 based on widget_id
    const mapArr2 = arr2.reduce((acc, obj) => {
      acc[obj.i] = obj;
      return acc;
    }, {});
    // Update the values in arr1 based on widget_id
    const updatedArr1 = arr1.map((obj1) => {
      const correspondingObj2 = mapArr2[obj1.widget_id.toString()];
      if (correspondingObj2) {
        // Merge the properties from arr2 to arr1
        return { ...obj1, ...correspondingObj2 };
      }
      return obj1; // If no match found, keep the original object
    });
    return updatedArr1;
  }

  function updateWidgetStyles(arr1, arr2) {
    // Create a map for quick lookup of styles from array2 based on widget_id
    const mapArr2 = arr2.reduce((acc, obj) => {
      acc[obj.widget_id] = obj;
      return acc;
    }, {});
    // Update the widget_styles in array1 based on widget_id
    const updatedArr1 = arr1.map((obj1) => {
      const correspondingObj2 = mapArr2[obj1.widget_id];
      if (correspondingObj2) {
        // Merge the properties from arr2 to arr1
        return { ...obj1, widget_styles: [correspondingObj2] };
      }
      return obj1; // If no match found, keep the original object
    });
    return updatedArr1;
  }

  const addCustomWidget = (dataGrid) => {
    const tempOrder = JSON.parse(JSON.stringify(homepageOrder));
    const allData = JSON.parse(JSON.stringify(widgets));
    const customId = localStorage.getItem("customId");
    const updatedArray = updateObjects(tempOrder, updatedOrder.newLayout);
    const index = updatedArray.findIndex((obj) => obj.widget_id == customId);
    // updated styles
    const updatedStyles = JSON.parse(JSON.stringify(widgetsStyles));

    if (index !== -1) {
      // OLD
      // const newArray = [
      //   ...updatedArray.slice(0, index),
      //   dataGrid,
      //   ...updatedArray.slice(index + 1),
      // ];
      // const updatedData = { ...allData, homepageOrder: newArray };
      // dispatch(setStyling(updatedData));

      // NEW
      const newArray = [
        ...updatedArray.slice(0, index),
        dataGrid,
        ...updatedArray.slice(index + 1),
      ];
      const temp = updateWidgetStyles(newArray, updatedStyles);
      const updatedData = { ...allData, homepageOrder: temp };
      dispatch(setStyling(updatedData));
    } else {
      console.log("else");

      // OLD withoud updated styles when new widget dragged the old styles gets removed
      // updatedArray.push(dataGrid);
      // const updatedData = { ...allData, homepageOrder: updatedArray };
      // dispatch(setStyling(updatedData));

      // WORK HEREER
      // NEW for previous changes
      updatedArray.push(dataGrid);
      const temp = updateWidgetStyles(updatedArray, updatedStyles);
      const updatedData = { ...allData, homepageOrder: temp };
      dispatch(setStyling(updatedData));
    }
  };

  const handleResizing = (w, h, name) => {
    const obj = updatedOrder.newLayout.filter((item) => {
      return item.i == localStorage.getItem("customId");
    });
    const tempOrder = JSON.parse(JSON.stringify(homepageOrder));
    const updatedArray = updateObjects(tempOrder, updatedOrder.newLayout);
    const newObj = updatedArray.filter((item) => {
      return item.i == localStorage.getItem("customId");
    });
    if (obj.length) {
      const dataGrid = {
        ...newObj[0],
        widget_id: localStorage.getItem("customId"),
        // widget_id: Number(localStorage.getItem("customId")),
        i: localStorage.getItem("customId"),
        x: obj[0].x,
        y: obj[0].y,
        w: w,
        h: h,
      };
      addCustomWidget(dataGrid);
    } else {
      const dataGrid = {
        widget_id: localStorage.getItem("customId"),
        // widget_id: Number(localStorage.getItem("customId")),
        i: localStorage.getItem("customId"),
        x: 0,
        y: getHighestYValue(),
        w: w,
        h: h,
        widget_styles: [],
        widgetInfo: { name: name, id: localStorage.getItem("customId") },
      };
      addCustomWidget(dataGrid);
    }
  };

  const handleCustomStyles = (data) => {
    const id = localStorage.getItem("customId");
    const originalData = JSON.parse(JSON.stringify(widgets));
    const updatedStyles = JSON.parse(JSON.stringify(widgetsStyles));
    const updatedWigetObj = updatedStyles.find((item) => item.widget_id == id);

    // console.log("UPDATED>", updatedStyles);
    // console.log('ORIGINAL>',originalData.homepageOrder);

    const index = originalData.homepageOrder.findIndex(
      (item) => item.widget_id == id
    );
    const widgetToUpdate = originalData.homepageOrder.find(
      (item) => item.widget_id == id
    );

    const widgetType = localStorage.getItem("widgetName");

    if (widgetType === "column" || widgetType === "row") {
      // Accordion
      if (data.accordion) {
        if (!widgetToUpdate.widget_styles) {
          widgetToUpdate.widget_styles = [{}];
        } else if (widgetToUpdate.widget_styles.length === 0) {
          widgetToUpdate.widget_styles.push({});
        }
        if (widgetToUpdate) {
          if (
            data.column === "1" &&
            widgetToUpdate.widget_styles[0].accordion1
          ) {
            const accordionArray = [
              // ...widgetToUpdate.widget_styles[0].accordion1,
              ...updatedWigetObj.accordion1,
            ];
            accordionArray.push(data.accordion);
            widgetToUpdate.widget_styles[0] = {
              ...widgetToUpdate.widget_styles[0],
              ...updatedWigetObj,
              accordion1: accordionArray,
            };
          } else if (
            data.column === "2" &&
            widgetToUpdate.widget_styles[0].accordion2
          ) {
            const accordionArray = [
              // ...widgetToUpdate.widget_styles[0].accordion2,
              ...updatedWigetObj.accordion2,
            ];
            accordionArray.push(data.accordion);
            widgetToUpdate.widget_styles[0] = {
              ...widgetToUpdate.widget_styles[0],
              ...updatedWigetObj,
              accordion2: accordionArray,
            };
          } else if (
            data.column === "3" &&
            widgetToUpdate.widget_styles[0].accordion3
          ) {
            const accordionArray = [
              // ...widgetToUpdate.widget_styles[0].accordion3,
              ...updatedWigetObj.accordion3,
            ];
            accordionArray.push(data.accordion);
            widgetToUpdate.widget_styles[0] = {
              ...widgetToUpdate.widget_styles[0],
              ...updatedWigetObj,
              accordion3: accordionArray,
            };
          } else {
            if (data.column === "1") {
              widgetToUpdate.widget_styles[0] = {
                ...widgetToUpdate.widget_styles[0],
                ...updatedWigetObj,
                accordion1: [data.accordion],
              };
            } else if (data.column === "2") {
              widgetToUpdate.widget_styles[0] = {
                ...widgetToUpdate.widget_styles[0],
                ...updatedWigetObj,
                accordion2: [data.accordion],
              };
            } else if (data.column === "3") {
              widgetToUpdate.widget_styles[0] = {
                ...widgetToUpdate.widget_styles[0],
                ...updatedWigetObj,
                accordion3: [data.accordion],
              };
            }
          }
        }
        originalData.homepageOrder[index] = widgetToUpdate;
        // Update remaining widgets styles to latest styles
        const updateStylesofOtherWidgets = originalData.homepageOrder.map(
          (item1) => {
            const item2 = updatedStyles.find(
              (item2) => item2.widget_id == item1.widget_id
            );
            if (item2 && item2.widget_id.toString() !== id.toString()) {
              return { ...item1, widget_styles: [item2] };
            } else {
              return item1;
            }
          }
        );
        originalData.homepageOrder = updateStylesofOtherWidgets;
      }
      // Text
      else if (data.text) {
        if (!widgetToUpdate.widget_styles) {
          widgetToUpdate.widget_styles = [{}];
        } else if (widgetToUpdate.widget_styles.length === 0) {
          widgetToUpdate.widget_styles.push({});
        }
        if (widgetToUpdate) {
          if (data.column === "1" && widgetToUpdate.widget_styles[0].text1) {
            const textArray = [...updatedWigetObj.text1];
            textArray.push(data.text);
            // console.log('HERERER');
            // console.log("WIDGET>",widgetToUpdate);
            widgetToUpdate.widget_styles[0] = {
              ...widgetToUpdate.widget_styles[0],
              ...updatedWigetObj,
              text1: textArray,
            };
          } else if (
            data.column === "2" &&
            widgetToUpdate.widget_styles[0].text2
          ) {
            const textArray = [...updatedWigetObj.text2];
            textArray.push(data.text);
            widgetToUpdate.widget_styles[0] = {
              ...widgetToUpdate.widget_styles[0],
              ...updatedWigetObj,
              text2: textArray,
            };
          } else if (
            data.column === "3" &&
            widgetToUpdate.widget_styles[0].text3
          ) {
            const textArray = [...updatedWigetObj.text3];
            textArray.push(data.text);
            widgetToUpdate.widget_styles[0] = {
              ...widgetToUpdate.widget_styles[0],
              ...updatedWigetObj,
              text3: textArray,
            };
          } else {
            if (data.column === "1") {
              widgetToUpdate.widget_styles[0] = {
                ...widgetToUpdate.widget_styles[0],
                ...updatedWigetObj,
                text1: [data.text],
              };
            } else if (data.column === "2") {
              widgetToUpdate.widget_styles[0] = {
                ...widgetToUpdate.widget_styles[0],
                ...updatedWigetObj,
                text2: [data.text],
              };
            } else if (data.column === "3") {
              widgetToUpdate.widget_styles[0] = {
                ...widgetToUpdate.widget_styles[0],
                ...updatedWigetObj,
                text3: [data.text],
              };
            }
          }
        }
        originalData.homepageOrder[index] = widgetToUpdate;
        // console.log("ORI>", originalData.homepageOrder);
        // console.log("UPDATED>", updatedStyles);
        // console.log("ID>", id);

        // Update remaining widgets styles to latest styles
        const updateStylesofOtherWidgets = originalData.homepageOrder.map(
          (item1) => {
            const item2 = updatedStyles.find(
              (item2) => item2.widget_id == item1.widget_id
            );
            if (item2 && item2.widget_id.toString() !== id.toString()) {
              return { ...item1, widget_styles: [item2] };
            } else {
              return item1;
            }
          }
        );
        originalData.homepageOrder = updateStylesofOtherWidgets;
        // console.log("FINAL>", updateStylesofOtherWidgets);
      }
      // Heading
      else if (data.heading) {
        if (!widgetToUpdate.widget_styles) {
          widgetToUpdate.widget_styles = [{}];
        } else if (widgetToUpdate.widget_styles.length === 0) {
          widgetToUpdate.widget_styles.push({});
        }
        if (widgetToUpdate) {
          if (data.column === "1" && widgetToUpdate.widget_styles[0].heading1) {
            const headingArray = [...updatedWigetObj.heading1];
            headingArray.push(data.heading);
            widgetToUpdate.widget_styles[0] = {
              ...widgetToUpdate.widget_styles[0],
              ...updatedWigetObj,
              heading1: headingArray,
            };
          } else if (
            data.column === "2" &&
            widgetToUpdate.widget_styles[0].heading2
          ) {
            const headingArray = [...updatedWigetObj.heading2];
            headingArray.push(data.heading);
            widgetToUpdate.widget_styles[0] = {
              ...widgetToUpdate.widget_styles[0],
              ...updatedWigetObj,
              heading2: headingArray,
            };
          } else if (
            data.column === "3" &&
            widgetToUpdate.widget_styles[0].heading3
          ) {
            const paragraphArray = [...updatedWigetObj.heading3];
            paragraphArray.push(data.heading);
            widgetToUpdate.widget_styles[0] = {
              ...widgetToUpdate.widget_styles[0],
              ...updatedWigetObj,
              heading3: paragraphArray,
            };
          } else {
            if (data.column === "1") {
              widgetToUpdate.widget_styles[0] = {
                ...widgetToUpdate.widget_styles[0],
                ...updatedWigetObj,
                heading1: [data.heading],
              };
            } else if (data.column === "2") {
              widgetToUpdate.widget_styles[0] = {
                ...widgetToUpdate.widget_styles[0],
                ...updatedWigetObj,
                heading2: [data.heading],
              };
            } else if (data.column === "3") {
              widgetToUpdate.widget_styles[0] = {
                ...widgetToUpdate.widget_styles[0],
                ...updatedWigetObj,
                heading3: [data.heading],
              };
            }
          }
        }
        originalData.homepageOrder[index] = widgetToUpdate;
        // Update remaining widgets styles to latest styles
        const updateStylesofOtherWidgets = originalData.homepageOrder.map(
          (item1) => {
            const item2 = updatedStyles.find(
              (item2) => item2.widget_id == item1.widget_id
            );
            if (item2 && item2.widget_id.toString() !== id.toString()) {
              return { ...item1, widget_styles: [item2] };
            } else {
              return item1;
            }
          }
        );
        originalData.homepageOrder = updateStylesofOtherWidgets;
      }
      // Paragraph
      else if (data.paragraph) {
        if (!widgetToUpdate.widget_styles) {
          widgetToUpdate.widget_styles = [{}];
        } else if (widgetToUpdate.widget_styles.length === 0) {
          widgetToUpdate.widget_styles.push({});
        }
        if (widgetToUpdate) {
          if (
            data.column === "1" &&
            widgetToUpdate.widget_styles[0].paragraph1
          ) {
            const paragraphArray = [...updatedWigetObj.paragraph1];
            paragraphArray.push(data.paragraph);
            widgetToUpdate.widget_styles[0] = {
              ...widgetToUpdate.widget_styles[0],
              ...updatedWigetObj,
              paragraph1: paragraphArray,
            };
          } else if (
            data.column === "2" &&
            widgetToUpdate.widget_styles[0].paragraph2
          ) {
            const paragraphArray = [...updatedWigetObj.paragraph2];
            paragraphArray.push(data.paragraph);
            widgetToUpdate.widget_styles[0] = {
              ...widgetToUpdate.widget_styles[0],
              ...updatedWigetObj,
              paragraph2: paragraphArray,
            };
          } else if (
            data.column === "3" &&
            widgetToUpdate.widget_styles[0].paragraph3
          ) {
            const paragraphArray = [...updatedWigetObj.paragraph3];
            paragraphArray.push(data.paragraph);
            widgetToUpdate.widget_styles[0] = {
              ...widgetToUpdate.widget_styles[0],
              ...updatedWigetObj,
              paragraph3: paragraphArray,
            };
          } else {
            if (data.column === "1") {
              widgetToUpdate.widget_styles[0] = {
                ...widgetToUpdate.widget_styles[0],
                ...updatedWigetObj,
                paragraph1: [data.paragraph],
              };
            } else if (data.column === "2") {
              widgetToUpdate.widget_styles[0] = {
                ...widgetToUpdate.widget_styles[0],
                ...updatedWigetObj,
                paragraph2: [data.paragraph],
              };
            } else if (data.column === "3") {
              widgetToUpdate.widget_styles[0] = {
                ...widgetToUpdate.widget_styles[0],
                ...updatedWigetObj,
                paragraph3: [data.paragraph],
              };
            }
          }
        }
        originalData.homepageOrder[index] = widgetToUpdate;
        // Update remaining widgets styles to latest styles
        const updateStylesofOtherWidgets = originalData.homepageOrder.map(
          (item1) => {
            const item2 = updatedStyles.find(
              (item2) => item2.widget_id == item1.widget_id
            );
            if (item2 && item2.widget_id.toString() !== id.toString()) {
              return { ...item1, widget_styles: [item2] };
            } else {
              return item1;
            }
          }
        );
        originalData.homepageOrder = updateStylesofOtherWidgets;
      }
      // Button
      else if (data.button) {
        if (!widgetToUpdate.widget_styles) {
          widgetToUpdate.widget_styles = [{}];
        } else if (widgetToUpdate.widget_styles.length === 0) {
          widgetToUpdate.widget_styles.push({});
        }
        if (widgetToUpdate) {
          if (data.column === "1" && widgetToUpdate.widget_styles[0].button1) {
            const buttonArray = [...updatedWigetObj.button1];
            buttonArray.push(data.button);
            widgetToUpdate.widget_styles[0] = {
              ...widgetToUpdate.widget_styles[0],
              ...updatedWigetObj,
              button1: buttonArray,
            };
          } else if (
            data.column === "2" &&
            widgetToUpdate.widget_styles[0].button2
          ) {
            const buttonArray = [...updatedWigetObj.button2];
            buttonArray.push(data.button);
            widgetToUpdate.widget_styles[0] = {
              ...widgetToUpdate.widget_styles[0],
              ...updatedWigetObj,
              button2: buttonArray,
            };
          } else if (
            data.column === "3" &&
            widgetToUpdate.widget_styles[0].button3
          ) {
            const buttonArray = [...updatedWigetObj.button3];
            buttonArray.push(data.button);
            widgetToUpdate.widget_styles[0] = {
              ...widgetToUpdate.widget_styles[0],
              ...updatedWigetObj,
              button3: buttonArray,
            };
          } else {
            if (data.column === "1") {
              widgetToUpdate.widget_styles[0] = {
                ...widgetToUpdate.widget_styles[0],
                ...updatedWigetObj,
                button1: [data.button],
              };
            } else if (data.column === "2") {
              widgetToUpdate.widget_styles[0] = {
                ...widgetToUpdate.widget_styles[0],
                ...updatedWigetObj,
                button2: [data.button],
              };
            } else if (data.column === "3") {
              widgetToUpdate.widget_styles[0] = {
                ...widgetToUpdate.widget_styles[0],
                ...updatedWigetObj,
                button3: [data.button],
              };
            }
          }
        }
        originalData.homepageOrder[index] = widgetToUpdate;
        // Update remaining widgets styles to latest styles
        const updateStylesofOtherWidgets = originalData.homepageOrder.map(
          (item1) => {
            const item2 = updatedStyles.find(
              (item2) => item2.widget_id == item1.widget_id
            );
            if (item2 && item2.widget_id.toString() !== id.toString()) {
              return { ...item1, widget_styles: [item2] };
            } else {
              return item1;
            }
          }
        );
        originalData.homepageOrder = updateStylesofOtherWidgets;
      } else {
        if (!widgetToUpdate.widget_styles) {
          widgetToUpdate.widget_styles = [{}];
        } else if (widgetToUpdate.widget_styles.length === 0) {
          widgetToUpdate.widget_styles.push({});
        }
        const mergedData = { ...updatedWigetObj, ...data };
        widgetToUpdate.widget_styles[0] = mergedData;
        originalData.homepageOrder[index] = widgetToUpdate;
      }
    }
    // FOR IMAGES
    else if (data.image) {
      if (!widgetToUpdate.widget_styles) {
        widgetToUpdate.widget_styles = [{}];
      } else if (widgetToUpdate.widget_styles.length === 0) {
        widgetToUpdate.widget_styles.push({});
      }
      if (widgetToUpdate.widget_styles[0].images) {
        const imagesArray = [...updatedWigetObj.images];
        imagesArray.push({ ...data.image });
        widgetToUpdate.widget_styles[0] = {
          ...widgetToUpdate.widget_styles[0],
          ...updatedWigetObj,
          images: imagesArray,
        };
      } else {
        widgetToUpdate.widget_styles[0] = {
          ...widgetToUpdate.widget_styles[0],
          ...updatedWigetObj,
          images: [{ ...data.image }],
        };
      }

      originalData.homepageOrder[index] = widgetToUpdate;
      // Update remaining widgets styles to latest styles
      const updateStylesofOtherWidgets = originalData.homepageOrder.map(
        (item1) => {
          const item2 = updatedStyles.find(
            (item2) => item2.widget_id == item1.widget_id
          );
          if (item2 && item2.widget_id.toString() !== id.toString()) {
            return { ...item1, widget_styles: [item2] };
          } else {
            return item1;
          }
        }
      );
      originalData.homepageOrder = updateStylesofOtherWidgets;
    } else {
      if (!widgetToUpdate.widget_styles) {
        widgetToUpdate.widget_styles = [{}];
      } else if (widgetToUpdate.widget_styles.length === 0) {
        widgetToUpdate.widget_styles.push({});
      }
      const mergedData = { ...updatedWigetObj, ...data };
      widgetToUpdate.widget_styles[0] = mergedData;
      originalData.homepageOrder[index] = widgetToUpdate;
    }
    dispatch(setStyling(originalData));
  };

  // const handleCustomStyles = (data) => {
  //   const id = localStorage.getItem("customId");
  //   const updatedStyles = JSON.parse(JSON.stringify(widgetsStyles));
  //   const updatedWigetObj = updatedStyles.find((item) => item.widget_id == id);
  //   const originalData = JSON.parse(JSON.stringify(widgets));

  //   let updatedData = { ...originalData }; // Make a shallow copy to avoid modifying the original data
  //   // Find the widget in homepageOrder with the given id
  //   const widgetToUpdate = updatedData.homepageOrder.find(
  //     (widget) => widget.widget_id == id
  //   );
  //   if (widgetToUpdate) {
  //     // Ensure that the widget_styles array is initialized with an empty object if it's empty
  //     if (!widgetToUpdate.widget_styles) {
  //       widgetToUpdate.widget_styles = [{}];
  //     } else if (widgetToUpdate.widget_styles.length === 0) {
  //       widgetToUpdate.widget_styles.push({}); // If empty, add an empty object
  //     }
  //     // Merge data from the parameter and stateObject
  //     if (data.hasOwnProperty("accordion")) {
  //       console.log("ACCORDION>>", updatedWigetObj);
  //       const mergedData = {
  //         ...updatedWigetObj,
  //       };
  //       if (updatedWigetObj.accordion) {
  //         console.log("IF");
  //         mergedData["accordion"] = [
  //           ...updatedWigetObj.accordion,
  //           data.accordion,
  //         ];
  //       } else {
  //         console.log("ELSE>");
  //         mergedData["accordion"] = [ data.accordion];
  //       }
  //       widgetToUpdate.widget_styles[0] = mergedData;
  //       console.log("MERGED>", mergedData);
  //     }else{
  //       const mergedData = { ...updatedWigetObj, ...data };
  //       widgetToUpdate.widget_styles[0] = mergedData;
  //       console.log("MERGED ELSE>", mergedData);
  //     }
  //     // return;
  //   }
  //   console.log("UPDATED>>",updatedData);
  //   dispatch(setStyling(updatedData));
  // };

  // OLD
  // const handleCustomStyles = (data) => {
  //   const id = localStorage.getItem("customId");
  //   const updatedStyles = JSON.parse(JSON.stringify(widgetsStyles));
  //   const updatedWigetObj = updatedStyles.find((item) => item.widget_id == id);
  //   const originalData = JSON.parse(JSON.stringify(widgets));
  //   let updatedData = { ...originalData }; // Make a shallow copy to avoid modifying the original data
  //   // Find the widget in homepageOrder with the given id
  //   const widgetToUpdate = updatedData.homepageOrder.find(
  //     (widget) => widget.widget_id == id
  //   );
  //   if (widgetToUpdate) {
  //     // Ensure that the widget_styles array is initialized with an empty object if it's empty
  //     if (!widgetToUpdate.widget_styles) {
  //       widgetToUpdate.widget_styles = [{}];
  //     } else if (widgetToUpdate.widget_styles.length === 0) {
  //       widgetToUpdate.widget_styles.push({}); // If empty, add an empty object
  //     }
  //     // Merge data from the parameter and stateObject
  //     const mergedData = { ...updatedWigetObj, ...data };
  //     // Update the first object in widget_styles with the merged data
  //     const firstStyleObject = widgetToUpdate.widget_styles[0];
  //     for (const [key, value] of Object.entries(mergedData)) {
  //       if (firstStyleObject.hasOwnProperty(key)) {
  //         // If the key already exists, update its value
  //         firstStyleObject[key] = value;
  //       } else {
  //         // If the key does not exist, add the key-value pair
  //         firstStyleObject[key] = value;
  //       }
  //     }
  //   }
  //   // console.log("UPDATED>", updatedData);
  //   dispatch(setStyling(updatedData));
  // };

  useEffect(() => {
    if (mainColor) {
      const updatedStyles = { ...updateStyles, mainColor: mainColor };
      dispatch(updateUniversalStyles(updatedStyles));
    }
  }, [mainColor]);

  const [picker, setPicker] = useState(false);
  const [widgetsColour, setWidgetColour] = useState({
    connect: { color: "#ffffff", is_default: "0" },
    giving: { color: "#ffffff", is_default: "0" },
    map: { color: "#ffffff", is_default: "0" },
    ourcode: { color: "#ffffff", is_default: "0" },
    serve: { color: "#ffffff", is_default: "0" },
    eventList: { color: "#ffffff", is_default: "0" },
    groups: { color: "#ffffff", is_default: "0" },
    custom: { color: "#ffffff", is_default: "0" },
  });

  const [picker2, setPicker2] = useState(false);
  const [defaultFontColor, setDefaultFontColor] = useState({
    text: { color: "red" },
    paragraph: { color: "yellow" },
    heading: { color: "black" },
  });

  useEffect(() => {
    if (widgetsColour) {
      const updatedStyles = {
        ...updateStyles,
        default_widget_colors: { ...widgetsColour, ...defaultFontColor },
      };
      dispatch(updateUniversalStyles(updatedStyles));
    }
  }, [widgetsColour]);

  const colorPickerRef = useRef(null);
  useOutsideClick(colorPickerRef, () => {
    setPicker(false);
    setPicker2(false);
  });
  const [selectedWidget, setSelectedWidget] = useState("connect");

  const handleWidgetChange = (event) => {
    setSelectedWidget(event.target.value);
  };

  const [selectedText, setSelectedText] = useState("text");
  const handleTextChange = (event) => {
    setSelectedText(event.target.value);
  };

  useEffect(() => {
    if (defaultFontColor) {
      const updatedStyles = {
        ...updateStyles,
        default_widget_colors: { ...widgetsColour, ...defaultFontColor },
      };
      dispatch(updateUniversalStyles(updatedStyles));
    }
  }, [defaultFontColor]);

  const localWidgetName = localStorage.getItem("widgetName");
  const namesArray = ["chat", "notes", "sermon"];

  return (
    <>
      <div className="mainsideBar">
        <div className="sideBarInner sidebaroption">
          <div className="sideBaroptionBox">
            <h5>Theme</h5>
            <div className="themelayout">
              <div
                className="themelayoutinnerbox lightbox"
                onClick={() => {
                  dispatch(updateTracker(true));
                  LighthandleClick();
                }}
              >
                <img src={Assets.lighttheme} alt="Theme Layout" />
                <span>Light Theme</span>
              </div>
              <div
                className="themelayoutinnerbox darkbox active"
                onClick={() => {
                  dispatch(updateTracker(true));
                  DarkhandleClick();
                }}
              >
                <img src={Assets.darktheme} alt="Theme Layout" />
                <span>Dark Theme</span>
              </div>
            </div>
          </div>
          <div className="sideBaroptionBox mb-4">
            <h5>Style</h5>
            <div className="themelayout stylemain">
              <div
                className="themelayoutinnerbox roundcorner active"
                onClick={() => {
                  dispatch(updateTracker(true));
                  roundhandleClick();
                }}
              >
                <span>Round Corners</span>
              </div>
              <div
                className="themelayoutinnerbox sharpcorner"
                onClick={() => {
                  dispatch(updateTracker(true));
                  sharphandleClick();
                }}
              >
                <span>Sharp Corners</span>
              </div>
            </div>
          </div>
          {/* <div className="coming-soon-overlay mt-2">
            <p>Coming Soon</p>
          </div> */}
          <div className="sideBaroptionBox my-2">
            <h5>Main Color</h5>
            <div className="coloroptions">
              <div className="colormain">
                <ul>
                  <li
                    className={`${mainColor === "#3478ff" && "active"}`}
                    onClick={() => setMainColor("#3478ff")}
                  >
                    <span className="blue"></span>
                  </li>
                  <li
                    className={`${mainColor === "#54cc80" && "active"}`}
                    onClick={() => setMainColor("#54cc80")}
                  >
                    <span className="green"></span>
                  </li>
                  <li
                    className={`${mainColor === "#cca454" && "active"}`}
                    onClick={() => setMainColor("#cca454")}
                  >
                    <span className="yellow"></span>
                  </li>
                </ul>
              </div>
              <div
                className="defaultButtons"
                onClick={() => {
                  setAdvanceDrop(!advanceDrop);
                }}
              >
                <a href="#" className="BlueButton">
                  Advance Options
                </a>
              </div>
            </div>
          </div>
          {/* Advance Options */}
          <div
            className={`advanceBox ${advanceDrop ? "open" : ""}`}
            style={{ overflow: "visible" }}
          >
            <h5>Default Widget Colour</h5>
            <div className="fillmain">
              <div className="fillselect">
                <select
                  style={{ textTransform: "capitalize" }}
                  value={selectedWidget}
                  onChange={handleWidgetChange}
                >
                  {widgets.widgets_list &&
                    widgets.widgets_list.length > 0 &&
                    widgets.widgets_list.slice(0, 6).map((widget) => {
                      return widget.name !== "player" ? (
                        <option
                          style={{ textTransform: "capitalize" }}
                          value={widget.name}
                        >
                          {widget.name}
                        </option>
                      ) : null;
                    })}
                  <option value="custom">Custom Widget</option>
                </select>
              </div>
            </div>
            <div
              className="d-flex align-items-center mt-2"
              style={{ position: "relative" }}
            >
              <div
                style={{
                  borderRadius: 100,
                  padding: 2,
                  borderColor: "#ffffff",
                  borderWidth: 1,
                  width: 36,
                  backgroundColor: "#ffffff",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="d-flex"
                onClick={() => setPicker(!picker)}
              >
                <span
                  className="green"
                  style={{
                    height: 34,
                    width: 34,
                    borderRadius: 50,
                    backgroundColor: widgetsColour[selectedWidget].color,
                  }}
                />
              </div>
              <div
                style={{
                  backgroundColor:
                    widgetsColour[selectedWidget].is_default == "1"
                      ? "dimgrey"
                      : "",
                  borderRadius: 4,
                  padding: 4,
                  marginLeft: 8,
                }}
                onClick={() => {
                  setWidgetColour((prev) => ({
                    ...prev,
                    [selectedWidget]: {
                      ...prev[selectedWidget],
                      is_default:
                        prev[selectedWidget].is_default === "0" ? "1" : "0",
                    },
                  }));
                }}
                className="linkIcon"
              >
                <img
                  src={Assets.defaultIcon}
                  style={{ height: 26, width: 26 }}
                  alt="default Image"
                />
              </div>
              {picker && (
                <SketchPicker
                  styles={{
                    default: {
                      picker: {
                        zIndex: 3,
                        //  zoom: "125%"
                      },
                    },
                  }}
                  className="picker"
                  ref={colorPickerRef}
                  color={widgetsColour[selectedWidget].color}
                  onChangeComplete={(color) => {
                    setWidgetColour((prev) => ({
                      ...prev,
                      [selectedWidget]: {
                        ...prev[selectedWidget],
                        color: color.hex, // Update the color property
                      },
                    }));
                  }}
                />
              )}
            </div>

            <h5>Default Font Colour</h5>
            <div className="fillmain">
              <div className="fillselect">
                <select
                  style={{ textTransform: "capitalize" }}
                  value={selectedText}
                  onChange={handleTextChange}
                >
                  {["text", "heading", "paragraph"].map((item) => {
                    return (
                      <option
                        style={{ textTransform: "capitalize" }}
                        value={item}
                      >
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div
              className="d-flex align-items-center mt-2"
              style={{ position: "relative" }}
            >
              <div
                style={{
                  borderRadius: 100,
                  padding: 2,
                  borderColor: "#ffffff",
                  borderWidth: 1,
                  width: 36,
                  backgroundColor: "#ffffff",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="d-flex"
                onClick={() => setPicker2(!picker2)}
              >
                <span
                  className="green"
                  style={{
                    height: 34,
                    width: 34,
                    borderRadius: 50,
                    backgroundColor: defaultFontColor[selectedText].color,
                  }}
                />
              </div>
              {picker2 && (
                <SketchPicker
                  styles={{
                    default: {
                      picker: {
                        zIndex: 3,
                        // zoom: "125%"
                      },
                    },
                  }}
                  ref={colorPickerRef}
                  className="picker"
                  color={defaultFontColor[selectedText].color}
                  onChangeComplete={(color) => {
                    setDefaultFontColor((prev) => ({
                      ...prev,
                      [selectedText]: {
                        ...prev[selectedText],
                        color: color.hex, // Update the color property
                      },
                    }));
                  }}
                />
              )}
            </div>
          </div>
          <div
            className="sideBaroptionBox mt-4"
            style={{ position: "relative", zIndex: 2 }}
          >
            <h5>Fonts</h5>
            <div className="fillmain">
              <div className="fillselect">
                <select>
                  <option value="Gilroy">Gilroy</option>
                  <option value="Frontage">Frontage</option>
                  <option value="Playfair Display">Playfair Display</option>
                  <option value="Rockwell">Rockwell</option>
                </select>
              </div>
            </div>
            <div className="themelayout stylemain fontMainboxes">
              <div
                className={`themelayoutinnerbox roundcorner ${
                  universalStyles.font === "gilroymedium" && "active"
                }`}
                onClick={() => {
                  dispatch(updateTracker(true));
                  GiloryhandleClick();
                }}
              >
                <span>Gilroy</span>
              </div>
              <div
                className={`themelayoutinnerbox roundcorner ${
                  universalStyles.font === "frontageregular" && "active"
                }`}
                onClick={() => {
                  dispatch(updateTracker(true));
                  fronthandleClick();
                }}
              >
                <span>Frontage</span>
              </div>
              <div
                className={`themelayoutinnerbox roundcorner ${
                  universalStyles.font === "playfair display" && "active"
                }`}
                onClick={() => {
                  dispatch(updateTracker(true));
                  playhandleClick();
                }}
              >
                <span>Playfair Display</span>
              </div>
              <div
                className={`themelayoutinnerbox roundcorner ${
                  universalStyles.font === "rockwellregular" && "active"
                }`}
                onClick={() => {
                  dispatch(updateTracker(true));
                  rockhandleClick();
                }}
              >
                <span>Rockwell</span>
              </div>
            </div>
          </div>
        </div>
        <div className="sideBarInner advanceoptionmain">
          <div className="sideBaroptionBox sidebaroption2">
            <h5>
              Widgets
              {/* <span>Drag to add widget</span> */}
            </h5>
            {!loading && (
              <div className="themelayout stylemain widgetlayout">
                {widgets.widgets_list &&
                  widgets.widgets_list.length > 0 &&
                  widgets.widgets_list.map((item) => {
                    return (pageId == 1 ||
                      pageId == 7 ||
                      pageId == 9 ||
                      pageId == 15) &&
                      item.id !== 9 &&
                      item.id !== 10 &&
                      item.id !== 11 &&
                      item.id !== 12 &&
                      item.id !== 13 &&
                      item.id !== 14 &&
                      item.id !== 15 &&
                      item.id !== 16 &&
                      item.id !== 17 &&
                      item.id !== 18 &&
                      item.id !== 19 &&
                      item.id !== 20 &&
                      item.id !== 21 &&
                      item.id !== 22 &&
                      item.id !== 23 &&
                      item.id !== 24 &&
                      item.id !== 25 &&
                      item.id !== 26 &&
                      !(pageId == 1 && item.id == 27) &&
                      !(pageId == 7 && item.id == 27) &&
                      !(pageId == 9 && item.id == 27) &&
                      !(pageId == 15 && item.id == 27) &&
                      !(pageId == 7 && item.id == 1) &&
                      !(pageId == 15 && item.id == 1) ? (
                      <DraggableItem
                        key={item.id}
                        item={item}
                        editingMode={editingMode}
                      />
                    ) : (pageId == 2 || pageId == 10) &&
                      item.id !== 1 &&
                      item.id !== 10 &&
                      item.id !== 11 &&
                      item.id !== 12 &&
                      item.id !== 13 &&
                      item.id !== 14 &&
                      item.id !== 15 &&
                      item.id !== 16 &&
                      item.id !== 17 &&
                      item.id !== 18 &&
                      item.id !== 19 &&
                      item.id !== 20 &&
                      item.id !== 21 &&
                      item.id !== 22 &&
                      item.id !== 23 &&
                      item.id !== 24 &&
                      item.id !== 25 &&
                      item.id !== 26 &&
                      !(pageId == 2 && item.id == 27) &&
                      !(pageId == 10 && item.id == 27) ? (
                      <DraggableItem
                        key={item.id}
                        item={item}
                        editingMode={editingMode}
                      />
                    ) : (pageId == 3 ||
                        pageId == 8 ||
                        pageId == 11 ||
                        pageId == 16) &&
                      item.id !== 1 &&
                      item.id !== 10 &&
                      item.id !== 11 &&
                      item.id !== 12 &&
                      item.id !== 20 &&
                      item.id !== 21 &&
                      item.id !== 22 &&
                      item.id !== 23 &&
                      item.id !== 24 &&
                      item.id !== 25 &&
                      item.id !== 26 &&
                      // Exclude item with id 3 when pageId is 8
                      !(pageId == 8 && item.id == 3) &&
                      !(pageId == 8 && item.id == 17) &&
                      !(pageId == 8 && item.id == 18) &&
                      !(pageId == 8 && item.id == 14) &&
                      !(pageId == 8 && item.id == 19) &&
                      !(pageId == 8 && item.id == 13) &&
                      // Exclude item with id 3 when pageId is 8
                      !(pageId == 16 && item.id == 3) &&
                      !(pageId == 16 && item.id == 17) &&
                      !(pageId == 16 && item.id == 18) &&
                      !(pageId == 16 && item.id == 14) &&
                      !(pageId == 16 && item.id == 19) &&
                      !(pageId == 16 && item.id == 13) &&
                      // Exclude item with id 27 when pageId is 3
                      !(pageId == 3 && item.id == 27) &&
                      !(pageId == 11 && item.id == 27) &&
                      !(pageId == 3 && item.id == 9) &&
                      !(pageId == 11 && item.id == 9) &&
                      !(pageId == 8 && item.id == 9) &&
                      !(pageId == 16 && item.id == 9) ? (
                      <DraggableItem
                        key={item.id}
                        item={item}
                        editingMode={editingMode}
                      />
                    ) : (pageId == 4 || pageId == 12) &&
                      item.id == 20 &&
                      item.id !== 21 &&
                      item.id !== 22 &&
                      item.id !== 23 &&
                      item.id !== 24 &&
                      item.id !== 25 &&
                      item.id !== 26 ? (
                      <DraggableItem
                        key={item.id}
                        item={item}
                        editingMode={editingMode}
                      />
                    ) : (pageId == 5 || pageId == 13) &&
                      (item.id == 21 ||
                        item.id == 22 ||
                        item.id == 23 ||
                        item.id == 24 ||
                        item.id == 25) ? (
                      <DraggableItem
                        key={item.id}
                        item={item}
                        editingMode={editingMode}
                      />
                    ) : (pageId == 6 || pageId == 14) &&
                      item.id !== 9 &&
                      item.id !== 10 &&
                      item.id !== 11 &&
                      item.id !== 12 &&
                      item.id !== 13 &&
                      item.id !== 14 &&
                      item.id !== 15 &&
                      item.id !== 16 &&
                      item.id !== 17 &&
                      item.id !== 18 &&
                      item.id !== 19 &&
                      item.id !== 20 &&
                      item.id !== 21 &&
                      item.id !== 22 &&
                      item.id !== 23 &&
                      item.id !== 24 &&
                      item.id !== 25 &&
                      !(pageId == 6 && item.id == 27) &&
                      !(pageId == 14 && item.id == 27) &&
                      !(pageId == 6 && item.id == 1) &&
                      !(pageId == 14 && item.id == 1) ? (
                      <DraggableItem
                        key={item.id}
                        item={item}
                        editingMode={editingMode}
                      />
                    ) : null;
                  })}
                <DraggableItem
                  key={"add_custom"}
                  item={{ id: "add_custom" }}
                  editingMode={editingMode}
                  openCustomSideBar={() => openCustomSideBar("default_widget")}
                />
                {pageId !== 4 &&
                  pageId !== 5 &&
                  pageId !== 12 &&
                  pageId !== 13 && (
                    <DraggableItem
                      key={"column"}
                      item={{ id: "column" }}
                      editingMode={editingMode}
                      openCustomSideBar={() => openCustomSideBar("column")}
                    />
                  )}
                {pageId !== 4 &&
                  pageId !== 5 &&
                  pageId !== 12 &&
                  pageId !== 13 && (
                    <DraggableItem
                      key={"row"}
                      item={{ id: "row" }}
                      editingMode={editingMode}
                      openCustomSideBar={() => openCustomSideBar("row")}
                    />
                  )}
              </div>
            )}
          </div>
          <div className="custom-widgetMain">
            <div className="custom-widgetInner">
              <input
                type="text"
                className="widgetInputName"
                placeholder="enter widget name"
                value={widgetName}
                onChange={(e) => setWidgetName(e.target.value)}
              />
              {error && (
                <span style={{ color: "#FF0000", fontSize: 14 }}>
                  Enter widget name first.
                </span>
              )}
              <div className="sideBaroptionBox">
                <h5>Style</h5>
                <div className="themelayout stylemain customwidgettyle">
                  <div
                    className={`themelayoutinnerbox roundcorner ${
                      customBorder === "round" ? "active" : ""
                    }`}
                    onClick={() => {
                      setCustomBorder("round");
                      handleCustomStyles({
                        border: "round",
                        defaultCorners: "false",
                      });
                    }}
                  >
                    <span>Round Corners</span>
                  </div>
                  <div
                    className={`themelayoutinnerbox sharpcorner ${
                      customBorder === "sharp" ? "active" : ""
                    }`}
                    onClick={() => {
                      setCustomBorder("sharp");
                      handleCustomStyles({
                        border: "sharp",
                        defaultCorners: "false",
                      });
                    }}
                  >
                    <span>Sharp Corners</span>
                  </div>
                  <div
                    className={`themelayoutinnerbox sharpcorner ${
                      customBorder === "default" ? "active" : ""
                    }`}
                    style={{
                      borderRadius: universalStyles.corners == "round" ? 10 : 0,
                    }}
                    onClick={() => {
                      setCustomBorder("default");
                      handleCustomStyles({
                        defaultCorners: "true",
                        border: universalStyles.corners,
                      });
                    }}
                  >
                    <span>Default Corners</span>
                  </div>
                </div>
              </div>
              <div className="sideBaroptionBox">
                <h5>Size</h5>
                {/* for page 1>home */}
                {pageId == 1 || pageId == 7 || pageId == 9 ? (
                  <div className="customwidgetsizeMain">
                    <div className="customwidgetsize">
                      <div
                        onClick={() => {
                          const id = localStorage.getItem("customId");
                          // console.log("HERE>>", id);
                          if (id === null) {
                            getWidgetId(1, 3);
                          } else {
                            handleResizing(1, 3);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                        className="customthemelayoutinnerbox roundcorner"
                      >
                        <span>Widget Size 1</span>
                      </div>
                      <div
                        onClick={() => {
                          const id = localStorage.getItem("customId");
                          // console.log("HERE>>", id);
                          if (id === null) {
                            getWidgetId(2, 6);
                          } else {
                            handleResizing(2, 6);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                        className="customthemelayoutinnerbox roundcorner"
                      >
                        <span>Widget Size 2</span>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        const id = localStorage.getItem("customId");
                        // console.log("HERE>>", id);
                        if (id === null) {
                          getWidgetId(1, 6);
                        } else {
                          handleResizing(1, 6);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                      className="customwidgetsize"
                    >
                      <div className="customthemelayoutinnerbox roundcorner">
                        <span>Widget Size 3</span>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        const id = localStorage.getItem("customId");
                        // console.log("HERE>>", id);
                        if (id === null) {
                          getWidgetId(2, 3);
                        } else {
                          handleResizing(2, 3);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                      className="customwidgetsize cutomfullwidth"
                    >
                      <div className="customthemelayoutinnerbox roundcorner">
                        <span>Widget Size 4</span>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        const id = localStorage.getItem("customId");
                        // console.log("HERE>>", id);
                        if (id === null) {
                          getWidgetId(2, 2);
                        } else {
                          handleResizing(2, 2);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                      className="customwidgetsize cutomfullwidth"
                    >
                      <div
                        className="customthemelayoutinnerbox roundcorner"
                        style={{ height: "40px" }}
                      >
                        <span>Widget Size 5</span>
                      </div>
                    </div>
                  </div>
                ) : pageId == 6 || pageId == 14 ? (
                  <div className="customwidgetsizeMain">
                    <div className="customwidgetsize">
                      <div
                        onClick={() => {
                          const id = localStorage.getItem("customId");
                          // console.log("HERE>>", id);
                          if (id === null) {
                            getWidgetId(3, 3);
                          } else {
                            handleResizing(3, 3);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                        className="customthemelayoutinnerbox roundcorner"
                      >
                        <span>Widget Size 1</span>
                      </div>
                      <div
                        onClick={() => {
                          const id = localStorage.getItem("customId");
                          // console.log("HERE>>", id);
                          if (id === null) {
                            getWidgetId(2, 2);
                          } else {
                            handleResizing(2, 2);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                        className="customthemelayoutinnerbox roundcorner"
                      >
                        <span>Widget Size 2</span>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        const id = localStorage.getItem("customId");
                        // console.log("HERE>>", id);
                        if (id === null) {
                          getWidgetId(3, 6);
                        } else {
                          handleResizing(3, 6);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                      className="customwidgetsize"
                    >
                      <div className="customthemelayoutinnerbox roundcorner">
                        <span>Widget Size 3</span>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        const id = localStorage.getItem("customId");
                        // console.log("HERE>>", id);
                        if (id === null) {
                          getWidgetId(6, 6);
                        } else {
                          handleResizing(6, 6);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                      className="customwidgetsize cutomfullwidth"
                    >
                      <div className="customthemelayoutinnerbox roundcorner">
                        <span>Widget Size 4</span>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        const id = localStorage.getItem("customId");
                        // console.log("HERE>>", id);
                        if (id === null) {
                          getWidgetId(6, 3);
                        } else {
                          handleResizing(6, 3);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                      className="customwidgetsize cutomfullwidth"
                    >
                      <div
                        className="customthemelayoutinnerbox roundcorner"
                        style={{ height: "40px" }}
                      >
                        <span>Widget Size 5</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  // for page 2> watch
                  <div className="customwidgetsizeMain">
                    {localWidgetName === "watch_player" ? (
                      <div className="customwidgetsize">
                        <div
                          onClick={() => {
                            const id = localStorage.getItem("customId");
                            if (id === null) {
                              getWidgetId(3, 5);
                            } else {
                              handleResizing(3, 5);
                            }
                          }}
                          style={{ cursor: "pointer" }}
                          className="customthemelayoutinnerbox roundcorner"
                        >
                          <span>Widget Size 1</span>
                        </div>
                        <div
                          onClick={() => {
                            const id = localStorage.getItem("customId");
                            if (id === null) {
                              getWidgetId(4, 6);
                            } else {
                              handleResizing(4, 6);
                            }
                          }}
                          style={{ cursor: "pointer" }}
                          className="customthemelayoutinnerbox roundcorner"
                        >
                          <span>Widget Size 2</span>
                        </div>
                      </div>
                    ) : namesArray.includes(localWidgetName) ? (
                      <>
                        <div className="customwidgetsize">
                          <div
                            onClick={() => {
                              const id = localStorage.getItem("customId");
                              if (id === null) {
                                getWidgetId(1, 5);
                              } else {
                                handleResizing(1, 5);
                              }
                            }}
                            style={{ cursor: "pointer" }}
                            className="customthemelayoutinnerbox roundcorner"
                          >
                            <span>Widget Size 1</span>
                          </div>
                          <div
                            onClick={() => {
                              const id = localStorage.getItem("customId");
                              if (id === null) {
                                getWidgetId(3, 6);
                              } else {
                                handleResizing(3, 6);
                              }
                            }}
                            style={{ cursor: "pointer" }}
                            className="customthemelayoutinnerbox roundcorner"
                          >
                            <span>Widget Size 2</span>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            const id = localStorage.getItem("customId");
                            if (id === null) {
                              getWidgetId(1, 4);
                            } else {
                              handleResizing(1, 4);
                            }
                          }}
                          style={{ cursor: "pointer" }}
                          className="customwidgetsize"
                        >
                          <div className="customthemelayoutinnerbox roundcorner">
                            <span>Widget Size 3</span>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            const id = localStorage.getItem("customId");
                            if (id === null) {
                              getWidgetId(3, 4);
                            } else {
                              handleResizing(3, 4);
                            }
                          }}
                          style={{ cursor: "pointer" }}
                          className="customwidgetsize cutomfullwidth"
                        >
                          <div className="customthemelayoutinnerbox roundcorner">
                            <span>Widget Size 4</span>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            const id = localStorage.getItem("customId");
                            if (id === null) {
                              getWidgetId(2, 2);
                            } else {
                              handleResizing(2, 2);
                            }
                          }}
                          style={{ cursor: "pointer" }}
                          className="customwidgetsize cutomfullwidth"
                        >
                          <div
                            className="customthemelayoutinnerbox roundcorner"
                            style={{ height: "40px" }}
                          >
                            <span>Widget Size 5</span>
                          </div>
                        </div>
                      </>
                    ) : localWidgetName === "watch" ? (
                      <div className="customwidgetsize">
                        <div
                          onClick={() => {
                            const id = localStorage.getItem("customId");
                            if (id === null) {
                              getWidgetId(4, pageType === "web" ? 9 : 11);
                            } else {
                              handleResizing(4, pageType === "web" ? 9 : 11);
                            }
                          }}
                          style={{ cursor: "pointer" }}
                          className="customthemelayoutinnerbox roundcorner"
                        >
                          <span>Widget Size 1</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="customwidgetsize">
                          <div
                            onClick={() => {
                              const id = localStorage.getItem("customId");
                              // console.log("HERE>>", id);
                              if (id === null) {
                                pageId !== 5 && pageId !== 13
                                  ? getWidgetId(2, 3)
                                  : pageId !== 13
                                  ? getWidgetId(3, 3)
                                  : getWidgetId(1, 3);
                              } else {
                                pageId !== 5 && pageId !== 13
                                  ? handleResizing(2, 3)
                                  : pageId !== 13
                                  ? handleResizing(3, 3)
                                  : handleResizing(1, 3);
                              }
                            }}
                            style={{ cursor: "pointer" }}
                            className="customthemelayoutinnerbox roundcorner"
                          >
                            <span>Widget Size 1</span>
                          </div>
                          <div
                            onClick={() => {
                              const id = localStorage.getItem("customId");
                              // console.log("HERE>>", id);
                              if (id === null) {
                                pageId !== 5 && pageId !== 13
                                  ? getWidgetId(4, 6)
                                  : pageId !== 13
                                  ? getWidgetId(2, 3)
                                  : getWidgetId(1, 3);
                              } else {
                                pageId !== 5 && pageId !== 13
                                  ? handleResizing(4, 6)
                                  : pageId !== 13
                                  ? handleResizing(2, 3)
                                  : handleResizing(1, 3);
                              }
                            }}
                            style={{ cursor: "pointer" }}
                            className="customthemelayoutinnerbox roundcorner"
                          >
                            <span>Widget Size 2</span>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            const id = localStorage.getItem("customId");
                            // console.log("HERE>>", id);
                            if (id === null) {
                              pageId !== 5 && pageId !== 13
                                ? getWidgetId(2, 6)
                                : pageId !== 13
                                ? getWidgetId(3, 6)
                                : getWidgetId(1, 4);
                            } else {
                              pageId !== 5 && pageId !== 13
                                ? handleResizing(2, 6)
                                : pageId !== 13
                                ? handleResizing(3, 6)
                                : handleResizing(1, 4);
                            }
                          }}
                          style={{ cursor: "pointer" }}
                          className="customwidgetsize"
                        >
                          <div className="customthemelayoutinnerbox roundcorner">
                            <span>Widget Size 3</span>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            const id = localStorage.getItem("customId");
                            // console.log("HERE>>", id);
                            if (id === null) {
                              pageId !== 5 && pageId !== 13
                                ? getWidgetId(4, 3)
                                : pageId !== 13
                                ? getWidgetId(6, 6)
                                : getWidgetId(1, 4);
                            } else {
                              pageId !== 5 && pageId !== 13
                                ? handleResizing(4, 3)
                                : pageId !== 13
                                ? handleResizing(6, 6)
                                : handleResizing(1, 4);
                            }
                          }}
                          style={{ cursor: "pointer" }}
                          className="customwidgetsize cutomfullwidth"
                        >
                          <div className="customthemelayoutinnerbox roundcorner">
                            <span>Widget Size 4</span>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            const id = localStorage.getItem("customId");
                            if (id === null) {
                              pageId !== 5 && pageId !== 13
                                ? getWidgetId(4, 2)
                                : pageId !== 13
                                ? getWidgetId(6, 3)
                                : getWidgetId(1, 3);
                            } else {
                              pageId !== 5 && pageId !== 13
                                ? handleResizing(4, 2)
                                : pageId !== 13
                                ? handleResizing(6, 3)
                                : handleResizing(1, 3);
                            }
                          }}
                          style={{ cursor: "pointer" }}
                          className="customwidgetsize cutomfullwidth"
                        >
                          <div
                            className="customthemelayoutinnerbox roundcorner"
                            style={{ height: "40px" }}
                          >
                            <span>Widget Size 5</span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>

              <div className="sideBaroptionBox">
                <h5>Text</h5>
                <div className="themelayout stylemain fontMainboxes">
                  <DragableTool
                    title={"Text"}
                    id="text"
                    handleCustomStyles={handleCustomStyles}
                  />
                  {/* <div
                    onClick={() =>
                      handleCustomStyles({ text_alignmnet: "topLeft" })
                    }
                    className="themelayoutinnerbox roundcorner"
                  >
                    <span>Text</span>
                  </div> */}
                  <DragableTool
                    title={"Heading"}
                    id="heading"
                    handleCustomStyles={handleCustomStyles}
                  />
                  <DragableTool
                    title={"Paragraph"}
                    id="paragraph"
                    handleCustomStyles={handleCustomStyles}
                  />
                  <DragableTool
                    title={"Button"}
                    id="button"
                    handleCustomStyles={handleCustomStyles}
                  />
                  <DragableTool
                    title={"Image"}
                    id="image"
                    handleCustomStyles={handleCustomStyles}
                  />
                  {(localWidgetName === "column" ||
                    localWidgetName === "row") && (
                    <DragableTool
                      title={"Accordion"}
                      id="accordion"
                      handleCustomStyles={handleCustomStyles}
                    />
                  )}
                </div>
                <div className="textmain">
                  <div
                    onClick={closeWidgetSideBar}
                    className="defaultButtons savebutton"
                  >
                    <a href="#" className="BlueButton">
                      Save Changes
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
