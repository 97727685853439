import React, { useState, useEffect, useRef, useCallback } from "react";
// import GridLayout from "react-grid-layout";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
//Components
import ConnectWidget from "../../pages/Widgets/connectWidget";
import GivingWidget from "../../pages/Widgets/givingWidget";
import GroupWidget from "../../pages/Widgets/groupWidget";
import QuoteWidget from "../../pages/Widgets/quoteWidget";
import GroupWidget2 from "../../pages/Widgets/groupWidget2";
import JoinWidget from "../../pages/Widgets/joinWidget";
import "./styles.css";
import "../../pages/home/styles.css";
import EventListWidget from "../../pages/Widgets/eventListWidget";
import {
  setEdit,
  setStyling,
  submitRequest,
  updateHomePageOrder,
  updateTracker,
  setStopDrag,
} from "../../store/slices/bodyStyling";
import CustomWidget from "../../pages/Widgets/customWidget";
import { postRequest } from "../../utills/requests";
import toast from "react-hot-toast";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import ColumnWidget from "../../pages/Widgets/columnWidget";
import RowWidget from "../../pages/Widgets/rowWidget";
import SingleWidget from "../../pages/Widgets/SingleWidget";
import GivingDonateWidget from "../../pages/Widgets/givingDonateWidget";
import GivingGoalWidget from "../../pages/Widgets/GivingGoal";
import AnnualBudgetWidget from "../../pages/Widgets/AnnualBudget";
import BudgetResourceWidget from "../../pages/Widgets/BudgetResources";
import GivingStatement from "../../pages/Widgets/GivingStatement";
import HaveGiven from "../../pages/Widgets/HaveGiven";
import GivingGraph from "../../pages/Widgets/GivingGraph";
import GroupListWidget from "../../pages/Widgets/GroupListWidget";
import SongsWidget from "../../pages/Widgets/SongsWidget";
import TeamMemberWidget from "../../pages/Widgets/TeamMemberWidget";
import ChatWidget from "../../pages/Widgets/chatWidget";
import TeamChatWidget from "../../pages/Widgets/TeamChatWidget";
import CalenderWidget from "../../pages/Widgets/CalenderWidget";
import TeamDetailWidget from "../../pages/Widgets/TeamDetailWidget";
import VideoWidget from "../../pages/Widgets/videoWidget";
import TeamListWidget from "../../pages/Widgets/TeamListWidget";

const GridLayout = WidthProvider(RGL);

const ServeGrid = ({ widgetsOrder, edithandleClick }) => {
  const dispatch = useDispatch();
  const widgetsData = useSelector((state) => state.bodyStyling.styling);
  const pageId = useSelector((state) => state.login.pageId);
  const styles = useSelector((state) => state.bodyStyling.universalStyles);
  const updatedOrder = useSelector((state) => state.bodyStyling.homePageOrder);
  const draggable = useSelector((state) => state.bodyStyling.isEdit);
  const stopDrag = useSelector((state) => state.bodyStyling.stopDrag);
  const activeTemplate = useSelector(
    (state) => state.bodyStyling.activeTemplate
  );

  const [widgets, setWidgets] = useState([]);
  const [deleteWidgetIds, setDeleteWidgetIds] = useState([]);

  const [playerHeight, setPlayerHeight] = useState(
    window.innerWidth > 2048 && window.innerWidth <= 2304 ? 6.5 : 5.5
  );

  useEffect(() => {
    if (widgetsOrder) {
      const newLayout = transformData(widgetsOrder);
      const data = { newLayout, pageId: pageId };
      data && dispatch(updateHomePageOrder(data));
      setWidgets(widgetsOrder);
    }
  }, [widgetsOrder]);
  const transformData = (data) => {
    return data.map(({ widget_id, x, y, w, h }) => {
      return {
        i: `${widget_id}`,
        x,
        y,
        w,
        h,
      };
    });
  };
  const [layout, setLayout] = useState([]);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const ref = useRef(null);
  const [widgetName, setWidgetName] = useState("");

  // Update Widgets order
  const updateWidgetsOrder = (oldOrder, newOrder) => {
    const updatedArray = oldOrder.map((item1) => {
      const item2 = newOrder.find((item2) => item2.i == item1.widget_id);
      if (item2) {
        return { ...item1, x: item2.x, y: item2.y };
      } else {
        return item1;
      }
    });
    return updatedArray;
  };
  // DEL
  const deleteWidget = async (widgetId, widgetName) => {
    let data = {
      page_id: pageId,
      widget_id: [widgetId],
      template_id:
        activeTemplate && activeTemplate.id ? activeTemplate.id : null,
    };

    if (widgetName === "watch_player" || widgetName === "sermon") {
      const tempData = JSON.parse(JSON.stringify(widgetsData));
      const widgetExist = tempData.homepageOrder.find(
        (item) =>
          item.widgetInfo.name ===
          (widgetName === "watch_player" ? "chat" : "notes")
      );

      if (widgetExist) {
        setWidgetName(widgetName);
        const ids = [widgetId, widgetExist.widget_id];
        setDeleteWidgetIds(ids);
        ref.current.open();
        return;
      }
    }

    try {
      dispatch(submitRequest(true));
      const resp = await postRequest({
        endpoint: "/delete-widget",
        payload: data,
      });
      const tempData = JSON.parse(JSON.stringify(widgetsData));
      if (resp.data.success) {
        const updatedWidgets = tempData.homepageOrder.filter(
          (item) => item.widget_id.toString() !== widgetId.toString()
        );
        const newOrder = updateWidgetsOrder(
          updatedWidgets,
          updatedOrder.newLayout
        );
        const newArray = { ...tempData, homepageOrder: newOrder };
        dispatch(setStyling(newArray));
        toast.success(resp.data.msg, { id: "delete" });
      } else {
        const updatedWidgets = tempData.homepageOrder.filter(
          (item) => item.widget_id.toString() !== widgetId.toString()
        );
        const newOrder = updateWidgetsOrder(
          updatedWidgets,
          updatedOrder.newLayout
        );
        const newArray = { ...tempData, homepageOrder: newOrder };
        dispatch(setStyling(newArray));
      }
    } catch (error) {
      console.log("DEL ERR>", error);
    } finally {
      dispatch(submitRequest(false));
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
      dispatch(setEdit({}));
    }
    setTimeout(() => {
      dispatch(setStopDrag("no"));
    }, 500);
  };

  const deleteWatchAndChat = async () => {
    const data = {
      page_id: pageId,
      widget_id: deleteWidgetIds,
    };
    dispatch(submitRequest(true));
    try {
      const resp = await postRequest({
        endpoint: "/delete-widget",
        payload: data,
      });
      if (resp.data.success) {
        const tempData = JSON.parse(JSON.stringify(widgetsData));
        const updatedWidgets = tempData.homepageOrder.filter(
          (item) => !deleteWidgetIds.includes(item.widget_id)
        );
        const newArray = { ...tempData, homepageOrder: updatedWidgets };
        dispatch(setStyling(newArray));
        toast.success(resp.data.msg, { id: "delete" });
      } else {
        toast.error(resp.data.msg, { id: "delete" });
      }
    } catch (error) {
      console.log("DEL ERR>", error);
    } finally {
      ref.current.close();
      dispatch(submitRequest(false));
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1940 && window.innerWidth > 1740) {
        setPlayerHeight(5.5);
      } else if (window.innerWidth >= 1536 && window.innerWidth < 1707) {
        setPlayerHeight(5.5);
      } else if (window.innerWidth == 1707) {
        setPlayerHeight(5.5);
      } else if (window.innerWidth > 2048 && window.innerWidth <= 2304) {
        setPlayerHeight(5.5);
      } else if (window.innerWidth > 2304 && window.innerWidth <= 3702) {
        setPlayerHeight(5.5);
      } else if (window.innerWidth == 2048) {
        setPlayerHeight(5.5);
      } else if (window.innerWidth <= 1490 && window.innerWidth > 1291) {
        setPlayerHeight(5);
      } else if (window.innerWidth <= 1290 && window.innerWidth > 981) {
        setPlayerHeight(4);
      } else if (window.innerWidth <= 990 && window.innerWidth > 891) {
        setPlayerHeight(3);
      } else if (window.innerWidth <= 750 && window.innerWidth > 540) {
        setPlayerHeight(3);
      } else if (window.innerWidth <= 540 && window.innerWidth > 350) {
        setPlayerHeight(2);
      } else if (window.innerWidth <= 350) {
        setPlayerHeight(1.5);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getRowHeight = () => {
    if (window.innerWidth > 1536 && window.innerWidth <= 1707) {
      return 100;
    } else if (window.innerWidth > 1707 && window.innerWidth <= 1920) {
      return 110;
    } else if (window.innerWidth > 1920 && window.innerWidth <= 2048) {
      return 130;
    } else if (window.innerWidth > 2048 && window.innerWidth <= 2304) {
      return 140;
    } else if (window.innerWidth > 2304 && window.innerWidth <= 3072) {
      return 170;
    } else if (window.innerWidth > 3072 && window.innerWidth <= 4608) {
      return 230;
    }
    return 90;
  };

  const handleUpdateHomePageOrder = useCallback(
    (newData) => {
      const { newLayout, pageId } = newData;
      const updatedData = newLayout.map((item) => ({
        ...item,
        y: Math.floor(item.y),
      }));
      dispatch(updateHomePageOrder({ newLayout: updatedData, pageId }));
    },
    [dispatch]
  );

  return (
    <>
      <GridLayout
        onLayoutChange={(newLayout) => {
          setWidgets((prev) => {
            const newArray = prev.map((widget) => {
              const newX = newLayout.find((item) => item.i == widget.widget_id);
              return { ...widget, x: newX.x };
            });
            return newArray;
          });
        }}
        onDragStop={(newLayout) => {
          if (widgets.length > 0) {
            handleUpdateHomePageOrder({ newLayout, pageId });
          }
          dispatch(updateTracker(true));
        }}
        className="layout"
        cols={6}
        rowHeight={getRowHeight()}
        width={screenSize.width - screenSize.width * 0.37}
        autoSize
        isBounded
        isResizable={false}
        allowOverlap={false}
        margin={styles.corners === "sharp" ? [0, 0] : [20, 20]}
        containerPadding={[0, 0]}
        isDraggable={draggable.isEditMode || stopDrag === "no" ? true : false}
      >
        {widgets &&
          widgets.map((item) => {
            return (
              <div
                style={{
                  zIndex: item.widget_id == draggable.widgtId ? 3 : 2,
                  position: "relative",
                  width: "100%",
                  ...(item.widgetInfo.name === "player" && {
                    borderRadius: styles.corners === "sharp" ? 0 : 20,
                  }),
                }}
                key={item.widget_id}
                data-grid={{
                  x: item.x,
                  y: item.y,
                  w: item.w,
                  h: item.widgetInfo.name === "player" ? playerHeight : item.h,
                }}
              >
                {item.widgetInfo.name === "player" ? (
                  <VideoWidget
                    widgetId={Number(item.widget_id)}
                    deleteWidget={deleteWidget}
                  />
                ) : item.widgetInfo.name === "connect" ? (
                  <ConnectWidget
                    widgetId={Number(item.widget_id)}
                    height={item.h}
                    width={item.w}
                    itemStyles={item.widget_styles[0]}
                    edithandleClick={edithandleClick}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : // item.widget_id == "3"
                item.widgetInfo.name === "giving" ? (
                  <GivingWidget
                    widgetId={Number(item.widget_id)}
                    height={item.h}
                    width={item.w}
                    itemStyles={item.widget_styles[0]}
                    edithandleClick={edithandleClick}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "ourcode" ? (
                  <QuoteWidget
                    widgetId={Number(item.widget_id)}
                    height={item.h}
                    width={item.w}
                    itemStyles={item.widget_styles[0]}
                    edithandleClick={edithandleClick}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "map" ? (
                  <GroupWidget
                    widgetId={Number(item.widget_id)}
                    height={item.h}
                    width={item.w}
                    itemStyles={item.widget_styles[0]}
                    edithandleClick={edithandleClick}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "serve" ? (
                  <GroupWidget2
                    widgetId={Number(item.widget_id)}
                    height={item.h}
                    width={item.w}
                    itemStyles={item.widget_styles[0]}
                    edithandleClick={edithandleClick}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "groups" ? (
                  <JoinWidget
                    widgetId={Number(item.widget_id)}
                    height={item.h}
                    width={item.w}
                    itemStyles={item.widget_styles[0]}
                    edithandleClick={edithandleClick}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "eventList" ? (
                  <EventListWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    edithandleClick={edithandleClick}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "column" ? (
                  <ColumnWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "row" ? (
                  <RowWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "default_widget" ? (
                  <CustomWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "watch" ? (
                  <SingleWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "giving-donate" ? (
                  <GivingDonateWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "giving-goal" ? (
                  <GivingGoalWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "annual-budget" ? (
                  <AnnualBudgetWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "budgeting-resources" ? (
                  <BudgetResourceWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "giving-statement" ? (
                  <GivingStatement
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "have-given" ? (
                  <HaveGiven
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "giving-graph" ? (
                  <GivingGraph
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "group-list" ? (
                  <GroupListWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "songs" ? (
                  <SongsWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "team-member" ? (
                  <TeamMemberWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "team-chat" ? (
                  <TeamChatWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "calendar" ? (
                  <CalenderWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "team-details" ? (
                  <TeamDetailWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : item.widgetInfo.name === "team-list" ? (
                  <TeamListWidget
                    widgetId={Number(item.widget_id)}
                    itemStyles={item.widget_styles[0]}
                    height={item.h}
                    width={item.w}
                    deleteWidget={deleteWidget}
                    x={item.x}
                  />
                ) : null}
              </div>
            );
          })}
      </GridLayout>
      <Popup
        lockScroll={true}
        contentStyle={{
          border: "unset",
          padding: 0,
          borderRadius: 10,
        }}
        ref={ref}
        modal={true}
        trigger={<div style={{ marginLeft: "unset" }} />}
        position="center center"
      >
        {(close) => (
          <div
            className="confirmation-dialog"
            style={{ backgroundColor: "black", borderRadius: 10, padding: 20 }}
          >
            <p style={{ fontSize: 20, color: "#ffffff", textAlign: "center" }}>
              Deleting {widgetName === "sermon" ? "Sermon" : "Player"} will also
              delete {widgetName === "sermon" ? "Notes" : "Chat"} widget!.
              <br /> Are you sure?
            </p>
            <div className="d-flex align-items-center justify-content-around">
              <button
                onClick={() => close()}
                style={{
                  fontSize: 20,
                  color: "grey",
                  outline: "unset",
                  backgroundColor: "transparent",
                  fontWeight: "bolder",
                }}
              >
                NO
              </button>
              <button
                onClick={() => deleteWatchAndChat()}
                style={{
                  fontSize: 20,
                  color: "red",
                  outline: "unset",
                  backgroundColor: "transparent",
                  fontWeight: "bolder",
                }}
              >
                YES
              </button>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default ServeGrid;
